import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/en"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.en"

const TermsOfUsePage = (props) => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Terms of Use | Jubarte Design"
      locale="en"
      altLocale="pt"
      pathname="/terms-of-use/"
      description="Check out our Terms of Use to learn how to make the most of Jubarte Design. Understand what unlimited graphic and web design means."
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom">
        <div className="w-md-80 w-lg-60 text-center mx-auto form-search-wrapper">
          <h1 className="display-4 font-size-48--md-down jd-h2-titles">
            <span className="font-weight-bold">Terms </span>of Use
          </h1>
        </div>
      </div>
    </div>
    <article className="jd-terms">
      <div className="container u-space-1-top">
        <div className="w-lg-90 mx-auto">
          <div className="mb-4">
            <span className="text-muted">Effective Date: May 25, 2020</span>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Introduction</h2>
            <p>
              Welcome to <b>Jubarte Design!</b> As you have just clicked our Terms of Use, please pause, grab a coffee,
              and carefully read the following pages. It will take you approximately 30 minutes.
            </p>
            <p>
              These Terms of Use (“<b>Terms</b>”, “<b>Terms of Service</b>”) are a contract between you (“
              <b>Customer</b>”, “<b>your</b>”, “<b>yours</b>”, “<b>users</b>”,“<b>registered users</b>”) and us (“
              <b>Jubarte Design</b>”, “<b>Company</b>”, “<b>we</b>”, “<b>our</b>”) and govern your use of our web pages
              located at{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>{" "}
              (“<b>Site</b>”), and all subdomains and sub-directories therein, and the Subscription or other services we
              provide (“<b>Service</b>”) operated by us from our offices within Canada.
            </p>
            <p>
              These Terms of Use are a legal contract, so if you are entering into this Agreement on behalf of a
              company, government agency, organization, or other legal entity, you represent that you have the authority
              to bind such entity to these Terms. (In this case, “<b>you</b>” and “<b>your</b>” would then refer to the
              entity.)
            </p>
            <p>
              We make no representation that the Site's materials are appropriate or available for use in other
              locations, and access to them from territories where its contents are illegal is prohibited. Those who
              choose to access them from locations outside Canada are responsible for compliance with all applicable
              laws.
            </p>
            <p>
              Our Privacy Policy also governs your use of our Site and Service. It explains how we collect, safeguard,
              and disclose information that results from your use of our web pages. Please read it{" "}
              <Link to="/en/privacy-policy/" className="jd-terms-link">
                here
              </Link>
              .
            </p>
            <p>
              Your Agreement with us includes these Terms and our Privacy Policy (“<b>Agreements</b>”). By using the
              Service and accessing the Site in any manner, you acknowledge that you have read and understood the
              Agreements, and agree to be bound by them. If you do not agree with (or cannot comply with) the
              Agreements, then you may not use the Service, but please let us know by sending us an{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Disagreement with Terms of Use"
                className="jd-terms-mailto"
              >
                e-mail
              </a>{" "}
              so we can try to find a solution.
            </p>
            <p>These Terms apply to all visitors, users, and others who wish to access or use the Service.</p>
            <p>Thank you for being responsible.</p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Communications</h2>
            <p>
              By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional
              materials and other information we may send by e-mail. However, you may opt-out of receiving any, or all,
              of these communications from us by following the unsubscribe link in each e-mail, by changing your e-mail
              preferences on your account management page or by sending us an{" "}
              <a href="mailto:contact@jubarte.design?subject=Opt-out from Communications" className="jd-terms-mailto">
                e-mail
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Use of the Service</h2>
            <p>
              The characteristics of the Service are defined by the type of Plan (collectively, “<b>Plans</b>”) that you
              subscribe to; however, all Plans offer unlimited requests of graphic design service and unlimited
              revisions. The Ocean Plan is the only plan that also includes unlimited requests of web design services.
              Each Plan has defined limitations in the number of brands, registered users, design turnaround, dedicated
              designer or web designer, and dedicated account manager. The details of each Plan are available on the
              Site.
            </p>
            <p>
              A Subscription is a measure that represents the fulfillment of your needs or prioritized demands, through
              our team and platform, within the deadlines defined for each contracted Plan. While we accept unlimited
              requests and revisions with a Subscription, our output volume and speed of delivery depends on many
              factors, such as: (i) the type of Plan selected for the Subscription; (ii) the volume of requests; (iii)
              and the complexity of requests.
            </p>
            <p>
              We do not guarantee the amount of work that we can deliver within a business day for a Subscription;
              however, you can increase the volume and speed of delivery by adding more Subscriptions to your account.
            </p>
            <p>
              Subject to your compliance with this Agreement, as well as your Subscription for an applicable Plan and
              our timely receipt of your associated payment(s), we will make the applicable Service available to you
              during the term to which you have subscribed. We will use commercially reasonable efforts to make the
              Service available subject to planned downtime and any unscheduled emergency maintenance. We may modify,
              replace, or discontinue the Service at any time, for any reason, without notice.
            </p>
            <p>
              We do our best to minimize any mistakes. However, due to the nature of creative design, we cannot
              guarantee all files delivered will be 100% error-free. When we deliver a design project (“
              <b>Design Project</b>”) to you, you agree to review and proof all files for any errors or omissions and
              notify us of any changes or corrections needed within seven (7) days of receipt. We will do our best to
              rush edits to correct any mistakes that you notify us about during this time. After that time, if you
              don’t request any changes, our platform will automatically mark the Design Project as completed; however,
              if you notify us of any errors after that time, we are not required to but intend to try to work with you
              to make corrections.
            </p>
            <p>
              You may use the Service for any number of projects and scope that you have subscribed for under the
              applicable Plan. We do not recommend the use of the Service for time-sensitive projects. However, we will
              do our best to work with you to accommodate any priority items and your timelines.
            </p>
            <p>
              You are responsible for obtaining and maintaining all telecommunications, broadband, and computer
              equipment and services needed to access and use the Service and for paying all fees related to that.
            </p>
            <p>
              Subject to your continued compliance with this Agreement, including timely payment of associated amounts
              due, we grant you a limited, revocable, non-exclusive, non-sublicensable, non-transferrable license to
              access and use the Service for your internal business purposes.
            </p>
            <p>
              You are, and will be, the sole and exclusive owner of all right, title, and interest in and to the
              deliverables of a Design Project, including all intellectual property rights therein. We agree that
              concerning any deliverables that may qualify as “work made in the course of employment” by our team of
              designers as defined in Canada’s Copyright Act (R.S.C. 1985, c, C-42), such deliverables are deemed a
              “work made in the course of employment” for you. To the extent that any deliverables do not constitute a
              “work made in the course of employment”, we irrevocably assign you all right, title, and interest
              worldwide in and to the deliverables, including all intellectual property rights therein. Notwithstanding
              the foregoing, the terms of this section are subject to your compliance with this Agreement and your full
              payment of applicable amounts due.
            </p>
            <p>
              We may use certain pre-existing materials to provide the Service to you. We and our licensors ("
              <b>Licensors</b>") are, and will remain, the sole and exclusive owners of all right, title, and interest
              in and to any pre-existing materials. We grant you a perpetual, worldwide, limited, royalty-free,
              non-transferable, non-sublicensable license to use, display, and distribute any pre-existing materials to
              the extent incorporated in, combined with, or otherwise necessary for the use of the Service or
              deliverables. We expressly reserve all other rights in and to such pre-existing materials as defined in
              the Licensed Content section below.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Purchases</h2>
            <p>
              If you wish to purchase any product or service made available through the Service (“
              <b>Purchase</b>”), you may be asked to supply certain information relevant to your Purchase including,
              without limitation, your credit card number, the expiration date of your credit card, your billing
              address, and your shipping information.
            </p>
            <p>
              You represent and warrant that: (i) you have the legal right to use any credit card(s) or another payment
              method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct
              and complete.
            </p>
            <p>
              We may employ the use of third-party services to facilitate payment and the completion of Purchases. By
              submitting your information, you grant us the right to provide the information to these third parties
              subject to our Privacy Policy.
            </p>
            <p>
              We reserve the right to refuse or cancel your order at any time for reasons including but not limited to:
            </p>
            <p className="jd-terms-list-item">(a) product or service availability;</p>
            <p className="jd-terms-list-item">(b) errors in the description or price of the product or service;</p>
            <p className="jd-terms-list-item">(c) error in your order;</p>
            <p className="jd-terms-list-item">(d) or other reasons.</p>
            <p>
              We reserve the right to refuse or cancel your order and terminate your account in the event of non-payment
              of amounts owed to us or if fraud, unauthorized or illegal transaction is suspected.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Subscriptions</h2>
            <p>
              Some parts of the Service are billed on a Subscription basis (“<b>Subscription(s)</b>”). You will be
              billed in advance on a recurring and periodic basis (“<b>Billing Cycle</b>”). Billing cycles are set
              either on a monthly, quarterly, or annual basis, depending on the type of Subscription plan you select
              when purchasing a Subscription.
            </p>
            <p>
              Before we have any obligation to provide the Service, you must pay for the Subscription (as well as
              applicable taxes) in full, in such amounts and for such Billing Cycle as specified during registration, as
              updated (prospectively, not retroactively) by you from time to time.
            </p>
            <p>
              At the end of each Billing Cycle, your Subscription will automatically renew under the same conditions
              unless you cancel it or Jubarte Design cancels it. You may cancel your Subscription renewal either through
              your online account management page or by contacting Jubarte Design ' customer support team.
            </p>
            <p>
              A valid payment method, including credit card, is required to process the payment for your Subscription
              and applicable taxes. You shall provide Jubarte Design with accurate and complete billing information
              including full name, address, state, zip code, telephone number, and a valid payment method information.
              By submitting such payment information, you automatically authorize Jubarte Design to charge all
              Subscription fees and applicable taxes incurred through your account to any such payment instruments.
            </p>
            <p>
              Should automatic billing fail to occur for any reason, Jubarte Design will issue an electronic invoice
              indicating that you must proceed manually, within a specific deadline date, with the full payment
              corresponding to the billing period, as stated on the invoice.
            </p>
            <p>
              Overdue charges will accrue interest monthly at the rate of 1.5% of the then-outstanding unpaid balance,
              or the maximum rate permitted by law, whichever is lower. We reserve the right to terminate your account
              in the event of non-payment of amounts owed to us.
            </p>
            <p>
              All amounts owed under this Agreement are non-cancelable and non-refundable, except as expressly provided
              in the section Refunds below.
            </p>
            <p>
              We hope you are pleased with our Service. If for whatever reason, you are not, you may cancel your
              Subscription with us at any time as your sole remedy. Upon cancellation, you will continue to have access
              to the Service and your design files through the end of your current paid billing term. Once the billing
              term has ended after cancellation, you will no longer have access to the Service and may lose access to
              your design files through the Site.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Free Trial</h2>
            <p>
              Jubarte Design may, at its sole discretion, offer a Subscription with a free trial for a limited time (“
              <b>Free Trial</b>”). You may be required to enter your billing information to sign up for Free Trial.
            </p>
            <p>
              If you enter your billing information when signing up for Free Trial, you will not be charged by Jubarte
              Design until the Free Trial has expired. On the last day of the Free Trial period you will be
              automatically charged the applicable Subscription fees for the type of Subscription you have selected,
              unless you cancelled your Subscription.
            </p>
            <p>
              At any time and without notice, Jubarte Design reserves the right to (i) modify Terms of Use of Free Trial
              offer, or (ii) cancel such Free Trial offer.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Beta Tests</h2>
            <p>
              From time to time and at our sole discretion, we may initiate Beta Tests (“<b>Beta Test(s)</b>”). We may
              implement, run, suspend, or terminate a Beta Test at any time without notice or liability, and do not
              guarantee that a tested product, service, modification, upgrade, or another adjustment to the Site,
              Service, or means of providing the Service, will become part of the Service.
            </p>
            <p>
              We may deliver (and subsequently cease delivering) a Beta Test to all or any subset of Jubarte Design
              Account holders at our discretion without prior permission at any time. We may adjust our prices
              accordingly if we decide to incorporate a tested product or service into the Jubarte Design platform.
              Jubarte Design accepts no liability for any consequences resulting from or related to your participation
              in a Beta Test.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Fee Changes</h2>
            <p>
              Jubarte Design, in its sole discretion and at any time, may modify Subscription fees for the
              Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing
              Cycle.
            </p>
            <p>
              We will provide you with reasonable prior notice of any change in Subscription fees to allow you to
              terminate your Subscription before such change becomes effective. We are not required to notify you of
              temporary promotions or reductions in fees.
            </p>
            <p>
              Your continued use of Service after Subscription fee change comes into effect constitutes your agreement
              to pay the modified Subscription fee amount.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Refunds</h2>
            <p>
              We can issue refunds for Subscriptions within fourteen (14) days of the original Purchase of the
              Subscription. After these fourteen days, refunds are not possible under any circumstance, including unused
              time.
            </p>
            <p>
              We may refuse a refund request if, in our sole discretion, we find evidence of fraud, refund abuse, or
              other manipulative behaviour.
            </p>
            <p>Promotions, upgrades, and adjustments to existing plans are non-refundable.</p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Contests, Sweepstakes, and Promotions</h2>
            <p>
              Any contests, sweepstakes or other promotions (collectively, “<b>Promotions</b>”) made available through
              the Service may be governed by rules that are separate from these Terms of Use. If you participate in any
              Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion
              conflict with these Terms of Use, Promotion rules will apply.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Customer Content</h2>
            <p>
              Our Service allows you to submit, link, store, share and otherwise make available certain information,
              text, artwork, graphics, stock photographs, audio, videos, typeface, designs, or other material (“
              <b>Customer Content</b>”). You are responsible for Content that you submit on or through Service,
              including its legality, reliability, and appropriateness.
            </p>
            <p>
              By submitting Customer Content on or through the Service, you grant us a worldwide, royalty-free,
              non-exclusive license to access and use such content to provide the Service. You also represent and
              warrant that: (i) Customer Content is yours (you own it), you have the right to use it and the right to
              grant us the rights and license as provided in these Terms, and (ii) that the submission of Customer
              Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract
              rights or any other rights of any person or entity.
            </p>
            <p>We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
            <p>
              We take no responsibility and assume no liability for Customer Content you or any third party submits on
              or through the Site.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Licensed Content</h2>
            <p>
              While you are and will be the sole and exclusive owner of all right, title, and interest in and to your
              Design Project, the content that we license to you (“<b>Licensed Content</b>”), including artworks, stock
              photographs, audios, videos, typefaces, designs and writing when incorporated into your Design Project is
              subject to the license described in this section. No rights are granted to you other than as expressly set
              forth herein.
            </p>
            <p>
              Subject to compliance with this Agreement and full payment of applicable amounts due, we grant you a
              revocable, non-exclusive, non-transferrable, royalty-free, worldwide right and license to the Licensed
              Content for your personal or professional use, limited to use in your Design Project.
            </p>
            <p>
              Except as expressly permitted by us, you agree not to, directly or indirectly, stockpile, sell, lease,
              sublicense, distribute, copy, reproduce, republish, reverse engineer, download, data mine, or modify any
              Licensed Content made available through the Stock Service provided in our Site.
            </p>
            <p>
              To the extent that we license the Licensed Content from any third party, including but not limited to
              Unsplash, Deposit Photos, and Getty Images (“<b>Licensors</b>”), you agree to comply with the relevant
              third-party license. Except with our written permission, you may not:
            </p>
            <p className="jd-terms-list-item">
              (a) Sell, resell, rent, lease, sublicense, assign, grant a security interest in, or otherwise transfer any
              part of your rights to use Licensed Content apart from a design deliverable prepared by us or as part of a
              design product for your personal use;
            </p>
            <p className="jd-terms-list-item">
              (b) Use Licensed Content in a pornographic, defamatory, or another unlawful manner;
            </p>
            <p className="jd-terms-list-item">
              (c) Falsely represent that you are the original creator of any Licensed Content;
            </p>
            <p className="jd-terms-list-item">
              (d) Change, alter, adapt, translate, convert, modify, or make any derivative works of any Licensed
              Content;
            </p>
            <p className="jd-terms-list-item">
              (e) Use Licensed Content in any way that allows others to download, extract, or redistribute Licensed
              Content as a standalone file or work.
            </p>
            <p>
              Our Licensors and we retain ownership over Licensed Content licensed from them, whether downloaded through
              our Stock Services or incorporated into your graphic design deliverable. We reserve the right to
              terminate, revoke, or withdraw all licenses upon your failure to comply with any provisions of this
              Agreement. In the event of any termination, you will have no further right to make use of the Licensed
              Content, which may include the Licensed Content that we have included in your design deliverable, except
              if used as a linked part of your delivered Design Project.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Company Content</h2>
            <p>
              Service and its original content (excluding Customer Content and Licensed Content that have specific rules
              as detailed above), features, and functionality are and will remain the exclusive property of Jubarte
              Design and its licensors. Service is protected by copyright, trademark, and other laws of foreign
              countries. Our trademarks and trade dress may not be used in connection with any product or service
              without the prior written consent of Jubarte Design.
            </p>
            <p>
              Also, content found on or through this Service (“<b>Company Content</b>”) are the property of Jubarte
              Design or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy,
              or use said Company Content, whether in whole or in part, for commercial purposes or personal gain,
              without express advance written permission from us.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Prohibited Uses</h2>
            <p>
              You may use Service only for lawful purposes and in accordance with the Agreements. You agree not to use
              Service:
            </p>
            <p className="jd-terms-list-item">
              (a) In any way that violates any applicable national or international law or regulation;
            </p>
            <p className="jd-terms-list-item">
              (b) To exploit, harm, or attempt to exploit or harm minors in any way by exposing them to inappropriate
              content or otherwise;
            </p>
            <p className="jd-terms-list-item">
              (c) To transmit, or procure the sending of, any advertising or promotional material, including any “junk
              mail”, “chain letter,” “spam,” or any other similar solicitation;
            </p>
            <p className="jd-terms-list-item">
              (d) To impersonate or attempt to impersonate Company, a Company employee, another user, or any other
              person or entity;
            </p>
            <p className="jd-terms-list-item">
              (e) In any way that infringes upon the rights of others, or in any form is illegal, threatening,
              fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or
              activity;
            </p>
            <p className="jd-terms-list-item">
              (f) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or
              which, as determined by us, may harm or offend Company or users of Service or expose them to liability.
            </p>
            <p>Additionally, you agree not to:</p>
            <p className="jd-terms-list-item">
              (a) Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with
              any other party’s use of Service, including their ability to engage in real-time activities through
              Service;
            </p>
            <p className="jd-terms-list-item">(b) Share usernames or passwords to access the Site;</p>
            <p className="jd-terms-list-item">
              (c) Abuse the Service or our team in any way, including using our Services for illegal purposes;
            </p>
            <p className="jd-terms-list-item">
              (d) Access or use the Services to monitor its availability, performance, or functionality for competitive
              purposes;
            </p>
            <p className="jd-terms-list-item">
              (e) Reverse engineer (except to the extent permitted explicitly by statutory law), decompile, disassemble,
              or otherwise attempt to discover source code, object code or underlying structures, ideas, or algorithms
              of the Service; and
            </p>
            <p className="jd-terms-list-item">
              (f) Use any robot, spider, or other automatic devices, process, or means to access Service for any
              purpose, including monitoring or copying any of the material on Service;
            </p>
            <p className="jd-terms-list-item">
              (g) Use any manual process to monitor or copy any of the material on Service or for any other unauthorized
              purpose without our prior written consent;
            </p>
            <p className="jd-terms-list-item">
              (h) Modify, translate, or create derivative works based on the Service;
            </p>
            <p className="jd-terms-list-item">
              (i) Use any device, software, or routine that interferes with the proper working of Service;
            </p>
            <p className="jd-terms-list-item">
              (j) Introduce any viruses, trojan horses, worms, logic bombs, or other malicious or technologically
              harmful material;
            </p>
            <p className="jd-terms-list-item">
              (k) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the
              server on which Service is stored, or any server, computer, or database connected to Service;
            </p>
            <p className="jd-terms-list-item">
              (l) Attack Service via a denial-of-service attack or a distributed denial-of-service attack;
            </p>
            <p className="jd-terms-list-item">(m) Take any action that may damage or falsify Company rating; and</p>
            <p className="jd-terms-list-item">
              (n) Otherwise, attempt to interfere with the proper working of Service.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">No Use By Minors</h2>
            <p>
              Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing
              or using the Service, you warrant and represent that you are at least eighteen (18) years of age and with
              the full authority, right, and capacity to enter into this Agreement and abide by all of the terms and
              conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the
              access and usage of Service.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">User Accounts</h2>
            <p>
              When you create an account with us, you guarantee that you are above the age of 18 and that the
              information you provide us (“<b>Registration Data</b>”) is accurate, complete, and current at all times.
              Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on
              Service.
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your account and password, including but not
              limited to the restriction of access to your computer and account. You agree to accept responsibility for
              all activities or actions that occur under your account, password or both, whether your password is with
              our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>
            <p>
              Each person who uses the Service must have a separate username and password. As mentioned in the section
              Prohibited Uses above, usernames and passwords to access the Service cannot be shared.
            </p>
            <p>
              You may not use as a username the name of another person or entity or that is not lawfully available for
              use, a name or trademark that is subject to any rights of another person or entity other than you, without
              appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene.
            </p>
            <p>
              We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in
              our sole discretion.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Confidential Information</h2>
            <p>
              For purposes of this Agreement, the non-public or proprietary information, including, without limitation,
              information relating to current or future business, products and services, research, images, development,
              design details, and specifications, and marketing plans are considered confidential (“
              <b>Confidential Information</b>”).
            </p>
            <p>
              You may disclose to us your Confidential Information during the course of our relationship. We agree to
              hold in confidence and not disclose to any third party any of your Confidential Information, except as
              approved or directed in writing by you and will use your Confidential Information for no purpose other
              than for the Services. We will limit access to your Confidential Information to only those employees,
              officers, directors, contractors, representatives, and agents involved in providing Services to you. We
              will be responsible to you for any breach of this provision by our employees, officers, directors,
              contractors, representatives, and agents.
            </p>
            <p>
              We may similarly disclose to you our Confidential Information during the course of our relationship. You
              agree to hold in confidence and not disclose any of our Confidential Information to any third party,
              except as approved or directed in writing by us and will use our Confidential Information for no purpose,
              except as permitted by this Agreement. You will limit access to our Confidential Information to only those
              employees, officers, directors, contractors, representatives, and agents to whom it is necessary to
              disclose our Confidential Information. You will be responsible to you for any breach of this provision by
              your employees, officers, directors, contractors, representatives, and agents.
            </p>
            <p>
              Notwithstanding anything to the contrary in this Agreement, the following is not Confidential Information:
            </p>
            <p className="jd-terms-list-item">
              (a) Information that was in the public domain at the time of its disclosure or has entered the public
              domain without breach of this Agreement;
            </p>
            <p className="jd-terms-list-item">
              (b) Information that was already in the rightful possession of a party at the time of disclosure;
            </p>
            <p className="jd-terms-list-item">
              (c) information that is independently developed by a party without breaching this Agreement; or
            </p>
            <p className="jd-terms-list-item">
              (d) information that becomes known to a party, without restriction, from a third-party source not directly
              or indirectly involving a breach of this.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Portfolio Rights</h2>
            <p>
              As a result of this, you grant us a limited, nonexclusive, non-sublicensable, royalty-free worldwide
              license to use, publish, and display any deliverables that we develop in connection with the Services for
              marketing and advertising (the “<b>Portfolio Rights License</b>”).
            </p>
            <p>
              You may revoke the Portfolio Rights License at any time by sending written notice to our{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Revoke Portfolio Rights License"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              . If you revoke the Portfolio Rights License, we will stop using your deliverables for marketing and
              advertising purposes. However, your deliverables may continue to exist elsewhere online such as where
              others have used the deliverables under the Portfolio Rights License.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Copyright Policy</h2>
            <p>
              We respect the intellectual property rights of others and take claims of copyright infringement seriously.
              Our policy is to respond to any claim that the Company Content posted on Service infringes on the
              copyright or other intellectual property rights (“<b>Infringement</b>”) of any person or entity.
            </p>
            <p>
              If you are a copyright owner or authorized on behalf of one, and you believe that the copyrighted work has
              been copied in a way that constitutes copyright infringement, please submit your claim via{" "}
              <a href="mailto:contact@jubarte.design?subject=Copyright Infringement" className="jd-terms-mailto">
                e-mail
              </a>
              , with the subject line: “Copyright Infringement” and include in your claim a detailed description of the
              alleged Infringement. A notification of alleged copyright infringement shall include as much detail as
              possible to allow us to identify the facts or circumstances, including, where possible:
            </p>
            <p className="jd-terms-list-item">
              (a) A physical or electronic signature of the owner (or person authorized to act on behalf of the owner)
              of the copyright that is allegedly infringed;
            </p>
            <p className="jd-terms-list-item">
              (b) Specific identification of each copyrighted work claimed to have been infringed;
            </p>
            <p className="jd-terms-list-item">
              (c) A description of where the material believed to be infringing is located on the Site or the Services
              (please be as detailed as possible and provide a URL to help us find the content you are reporting);
            </p>
            <p className="jd-terms-list-item">
              (d) Contact information for the complaining party, such as a complete name, address, telephone number, and
              e-mail address;
            </p>
            <p className="jd-terms-list-item">
              (e) A statement that the complaining party has a good faith belief that use of the work(s) in the manner
              complained of is not authorized by the copyright owner, its agent, or the law; and
            </p>
            <p className="jd-terms-list-item">
              (f) A statement that the information in the notification is accurate and that the complaining party is the
              owner of the right that is allegedly infringed or agent for the owner.
            </p>
            <p>
              You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or
              bad-faith claims on the Infringement of any Content found on the Service on your copyright.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Error Reporting and Feedback</h2>
            <p>
              You may provide us either directly by{" "}
              <a href="mailto:contact@jubarte.design?subject=Feedback" className="jd-terms-mailto">
                e-mail
              </a>
              , via the Service, via Site, or via third party sites and tools, information and feedback concerning
              errors, suggestions for improvements, ideas, problems, testimonials, complaints, and other matters related
              to our Service (“<b>Feedback</b>”).
            </p>
            <p>You acknowledge and agree that:</p>
            <p className="jd-terms-list-item">
              (a) You shall not retain, acquire or assert any intellectual property right or other rights, title or
              interest in or to the Feedback;
            </p>
            <p className="jd-terms-list-item">
              (b) Jubarte Design may have ideas in development similar to the Feedback;
            </p>
            <p className="jd-terms-list-item">
              (c) Feedback does not contain confidential information or proprietary information from you or any third
              party; and
            </p>
            <p className="jd-terms-list-item">
              (d) Jubarte Design is not under any obligation of confidentiality with respect to the Feedback.
            </p>
            <p>
              In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory
              laws, you grant Jubarte Design and its affiliates an exclusive, transferable, worldwide, irrevocable,
              free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create
              derivative works, publish, distribute and commercialize) the Feedback in any manner and for any purpose.
            </p>
            <p>The third-party sites and tools mentioned above include the following:</p>
            <p>
              <b>Rollbar</b>
            </p>
            <p>
              Rollbar is an error tracking service provided by Rollbar Inc. Find out more here:{" "}
              <a
                href="https://docs.rollbar.com/docs/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://docs.rollbar.com/docs/privacy-policy
              </a>
              .
            </p>
            <p>
              <b>Sentry</b>
            </p>
            <p>
              Sentry is an open-source error tracking solution provided by Functional Software Inc. More information is
              available here:{" "}
              <a
                href="https://sentry.io/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://sentry.io/privacy/
              </a>
              .
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Analytics</h2>
            <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
            <p>
              <b>Google Analytics</b>
            </p>
            <p>
              Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
              Google uses the data collected to track and monitor the use of our Service. This data is shared with other
              Google services. Google may use the collected data to contextualize and personalize the ads of its
              advertising network.
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              We also encourage you to review Google's policy for safeguarding your data:{" "}
              <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/analytics/answer/6004245
              </a>
              .
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Links To Other Web Sites</h2>
            <p>
              Our Service may contain links to third-party web sites or services that are not owned or controlled by
              Jubarte Design.
            </p>
            <p>
              Jubarte Design has no control over and assumes no responsibility for the content, privacy policies, or
              practices of any third-party web sites or services. We do not warrant the offerings of any of these
              entities/individuals or their websites.
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
              DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
              CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD-PARTY WEBSITES OR SERVICES.
            </p>
            <p>
              WE STRONGLY ADVISE YOU TO READ THE TERMS OF USE AND PRIVACY POLICIES OF ANY THIRD-PARTY WEBSITES OR
              SERVICES THAT YOU VISIT.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Disclaimer Of Warranty</h2>
            <p>
              We represent and warrant that you will receive a good and valid title license to all deliverables, free
              and clear of all encumbrances and liens of any kind, except for Licensed Content and other pre-existing
              materials, which may be subject to additional terms and restrictions.
            </p>
            <p>
              THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR
              THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN.
            </p>
            <p>
              NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
              TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
              LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
              SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
              RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER
              THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p>
              COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
              INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS, NON-INFRINGEMENT OF INTELLECTUAL
              PROPERTY, AND OTHER VIOLATION OF RIGHTS, EITHER ORAL OR WRITTEN, WHETHER ARISING BY LAW, COURSE OF
              DEALING, COURSE OF PERFORMANCE, USAGE, TRADE, OR OTHERWISE.
            </p>
            <p>
              THE PRECEDING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Limitation Of Liability</h2>
            <p>
              EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS
              FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER, IT ARISES (INCLUDING
              ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
              APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN THE USE OR INABILITY
              TO USE THE SITE, AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
              CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR LOST PROFITS, REVENUES, DATA,
              FINANCIAL LOSSES, PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU
              OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE
              PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND SERVICES, AND UNDER NO
              CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. THIS PARAGRAPH DOES NOT AFFECT ANY
              LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Indemnification</h2>
            <p>
              YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD US HARMLESS FROM AND AGAINST ANY CLAIMS, LIABILITIES, DAMAGES,
              LOSSES, AND EXPENSES, INCLUDING WITHOUT LIMITATION, REASONABLE ATTORNEY’S FEES AND COSTS, ARISING OUT OF
              OR IN ANY WAY CONNECTED TO CUSTOMER CONTENT OR USE OF THE SERVICE OR ANY DELIVERABLES. YOU SHALL COOPERATE
              AS REQUIRED BY US IN THE DEFENSE OF ANY CLAIM. WE RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND
              CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION BY YOU, AND YOU WILL NOT, IN ANY EVENT, SETTLE ANY CLAIM
              WITHOUT OUR PRIOR WRITTEN CONSENT.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Prohibited Jurisdiction</h2>
            <p>
              You will not use or access our Service if you are located in any jurisdiction in which the provision of
              our Service is prohibited under Canadian, the U.S. or other laws (a “<b>Prohibited Jurisdiction</b>”) and
              you will not provide access to our Service to any government, entity or individual located in any
              Prohibited Jurisdiction. You confirm that you are not named on any Canadian or U.S. government list of
              persons or entities prohibited to transact with any Canadian or U.S. person; (b) you are not a national
              of, or a company registered in, any Prohibited Jurisdiction; (c) you will not allow Authorized Users to
              access or use our Service in violation of any Canadian, the U.S. or other export embargoes, prohibitions
              or restrictions; and (d) you will comply with all laws regarding the transmission of data exported from
              the country in which you (or your Authorized Users) are located to Canada and the United States.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Assignment</h2>
            <p>
              This Agreement and the rights and obligations herein are personal to you. You may not assign or otherwise
              transfer this Agreement or any of your rights or obligations hereunder without our prior written consent.
              We may freely assign this Agreement, including, without limitation, in connection with a merger,
              acquisition, bankruptcy, reorganization, or sale of some or all of our assets or stock.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Termination</h2>
            <p>
              We may terminate this Agreement at any time upon notice if you default or breach this Agreement. Upon
              expiration or termination of your account or Subscription to a Service, all rights under this Agreement
              relating to such Service will immediately terminate. You will lose all access to the applicable Service,
              including access to your account and Customer Content or other files. If we terminate the Agreement for
              your breach, any licenses to Licensed Content will terminate.
            </p>
            <p>
              This Agreement will expire and terminate upon the expiration or termination of your account or
              Subscription to Service. You may cancel your Service or downgrade your Plan at any time through our
              website. If you cancel (or downgrade) a Service already paid, you must continue to pay for the rest of
              your Plan’s term (monthly, quarterly, annually) and you are not entitled to a refund except for what is
              defined in the section Refunds above.
            </p>
            <p>
              The confidentiality obligations under this Agreement will survive for five (5) years after the termination
              of this Agreement.
            </p>
            <p>
              All provisions of Terms which by their nature should survive termination shall survive termination,
              including, without limitation, accrued rights to payment, confidentiality obligations, ownership
              provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Governing Law</h2>
            <p>
              The laws of the Province of British Columbia and Canadian law applicable in British Columbia will govern
              any dispute (“<b>Dispute</b>”), cause of action or claim arising out of this Agreement or your use of our
              Service, including against any Jubarte Design Party without regard to any choice of law, conflicts of law
              or other principles that would result in the application of the laws or regulations of any other
              jurisdiction.
            </p>
            <p>
              If you breach this Agreement or violate our rights or another person’s rights, we may ask a court to stop
              you; if we bring a claim against you, you will reimburse us for our reasonable legal fees for that claim.
            </p>
            <p>
              If you have any kind of Dispute with us, the exclusive means of resolving it will be by confidential,
              binding arbitration before a single arbitrator chosen by you and Jubarte Design. You will give notice of
              your Dispute to us in writing.
            </p>
            <p>
              If we do not decide together on an arbitrator within fifteen (15) days after we receive that notice, we
              both agree to ask the British Columbia International Commercial Arbitration Centre (BCICAC) to appoint
              one.
            </p>
            <p>
              The arbitration will take place in Vancouver, British Columbia, under the Arbitration Act (British
              Columbia), and will follow the Domestic Commercial Arbitration Rules of the BCICAC.
            </p>
            <p>
              The arbitrator will have the right to decide how the costs should be divided between us. The arbitrator
              will have the right to accept whatever kind of evidence they think is appropriate and will have the right
              to make whatever award they consider fair and equitable, based on legal and equitable principles,
              including giving an order such as an injunction (to stop one of us from doing something) or an order that
              you or we pay damages to the other. The arbitrator's award can be entered into the British Columbia
              Supreme Court registry in Vancouver, British Columbia, and enforced in the same way as a court order if
              the court in the enforcing jurisdiction allows.
            </p>
            <p>JUBARTE DESIGN AND YOU AGREE THAT:</p>
            <p className="jd-terms-list-item">
              (A) ANY DISPUTE RESOLUTION PROCEEDINGS WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS,
              CONSOLIDATED OR REPRESENTATIVE ACTION;
            </p>
            <p className="jd-terms-list-item">(B) EACH WAIVE ANY RIGHT TO A TRIAL BY JURY;</p>
            <p className="jd-terms-list-item">
              (C) ANY CAUSE OF ACTION OR CLAIM ARISING OUT OF OR RELATING TO THESE TERMS MUST BE COMMENCED WITHIN ONE
              (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
              BARRED.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Changes To Service</h2>
            <p>
              We reserve the right to withdraw or amend our Service, and any service or material we provide via Site, in
              our sole discretion without notice. We will not be liable if all or any part of the Service is unavailable
              at any time or for any reason. From time to time, we may restrict access to some parts of Service, or the
              entire Service, to users, including registered users.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Amendments To Terms</h2>
            <p>
              We may amend Terms at any time by posting the amended terms on this Site. It is your responsibility to
              review these Terms periodically.
            </p>
            <p>
              Your continued use of the Site following the posting of revised Terms means that you accept and agree to
              the changes. You are expected to check this page frequently so you know of any changes, as they are
              binding on you.
            </p>
            <p>
              By continuing to access or use our Service after any revisions become effective, you agree to be bound by
              the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Waiver And Severability</h2>
            <p>
              No waiver by Company of any term or condition outlined in Terms shall be deemed a further or continuing
              waiver of such term or condition or a waiver of any other term or condition. Any failure of the Company to
              assert a right or provision under Terms shall not constitute a waiver of such right or provision.
            </p>
            <p>
              If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid,
              illegal or unenforceable for any reason, the remaining provisions of Terms will continue in full force and
              effect, and the invalid, illegal or unenforceable provision will be replaced by a valid, legal and
              enforceable provision or provisions that comes closest to the intent of the parties underlying the
              invalid, illegal or unenforceable provision.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Force Majeure</h2>
            <p>
              If we are unable to perform any obligation under this Agreement because of any matter beyond our
              reasonable control, including but not limited to pandemic or widespread outbreak of infectious diseases;
              any laws, orders, rules, regulations, acts or restraints of any government or governmental body or
              authority, civil or military, including the orders and judgments of courts and government shutdown;
              exceptionally severe weather, lightning, flood, earthquake; fire, explosion; acts of war, acts of
              terrorism, civil disorder, sabotage; extraterrestrial invasion, zombie attack; strikes, lockouts or labor
              disruptions (whether or not involving our employees), acts of government, loss of or problems with
              telecommunications, utility services or other third-party services, and hostile internet or network
              attacks (each, a “<b>Force Majeure Event</b>”), we will have no liability to you for such failure to
              perform; provided, however, that we will resume performance promptly upon removal of the circumstances
              constituting the Force Majeure Event.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Acknowledgment</h2>
            <p>
              BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
              SERVICE AND AGREE TO BE BOUND BY THEM.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Contact and Notices</h2>
            <p>
              Jubarte Design may give notice to you by placing a banner notice on the Site. We may also contact you or
              your authorized users through your Jubarte Design account or contact information you provide, such as
              e-mail or telephone or through your account on one or more Social Networks. If you fail to maintain
              accurate account information, such as contact information, you may not receive critical information about
              our Service or this Agreement.
            </p>
            <p>
              For any notice to Jubarte Design that you give under or regarding this Agreement, you must notify us by{" "}
              <a href="mailto:contact@jubarte.design?subject=Notice" className="jd-terms-mailto">
                e-mail
              </a>
              .
            </p>
            <p>
              For feedback, comments, or requests for technical support please send us an{" "}
              <a href="mailto:contact@jubarte.design?subject=Technical Support" className="jd-terms-mailto">
                e-mail
              </a>{" "}
              or contact us via chat on our Site at{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>
              .
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Entire Agreement</h2>
            <p>
              This Agreement, including the other documents referred to as applicable to the Service in this Agreement,
              constitutes the entire Agreement between us regarding our Service and supersede and replace any prior
              Agreements (oral or written) we might have had between us regarding Service.
            </p>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default TermsOfUsePage
